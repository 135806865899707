<template>
	<div :class="$route.name">
		<div class="footer_top wrapper_container bg_center">
			<div class="main_content">
				<img class="left_img" src="~@/assets/ke-img/Home/six-bg.webp" alt="six-bg" />
				<div class="content">
					<h1 class="ke_common_title">Get started today</h1>
					<p v-if="$store.getters.GetIsH5" class="ke_common_line line"></p>
					<p class="ke_common_msg">Download the TiFi app today from the Google Playstore or the Apple Store.</p>
					<a href="https://play.google.com/store/apps/details?id=ke.co.tifi.credit" target="_blank">
						<img src="~@/assets/ke-img/Home/google-play.svg" alt="google-play" />
					</a>
				</div>
			</div>
		</div>
		<!-- ------ -->
		<div class="footer_bottom wrapper_container bg_center">
			<div class="main_content">
				<div class="content">
					<div class="footer_01">
						<img src="~@/assets/ke-img/Home/tifi-logo.svg" alt="tifi-logo" />
						<p class="ke_footer_msg">
							Introducing TiFi by <span>MIB African Solutions</span>, the leading solution for earned wage access.
							Empower your employees with financial flexibility and control, and enhance your employer brand.
						</p>
					</div>
					<div class="footer_02">
						<h3 class="ke_footer_title">Site Map</h3>
						<ul>
							<li class="ke_footer_msg"><router-link to="/home">Home</router-link></li>
							<li class="ke_footer_msg"><router-link to="/about-us">About us</router-link></li>
							<li class="ke_footer_msg"><router-link to="/">Services</router-link></li>
							<li class="ke_footer_msg"><router-link to="/">Resources</router-link></li>
							<li class="ke_footer_msg"><router-link to="/faq">FAQS</router-link></li>
							<li class="ke_footer_msg">
								<router-link to="/terms-of-use">Terms Of Use</router-link>
							</li>
						</ul>
					</div>
					<div class="footer_03">
						<h3 class="ke_footer_title">Social</h3>
						<ul>
							<li class="ke_footer_msg">
								<a href="https://www.facebook.com/TiFi_Kenya">Facebook</a>
							</li>
							<li class="ke_footer_msg">
								<a href="https://www.instagram.com/tifi_kenya/">Instagram</a>
							</li>
							<li class="ke_footer_msg">
								<a href="https://www.linkedin.com/company/tifi-kenya/">Linkedin</a>
							</li>
						</ul>
					</div>
					<div class="footer_04">
						<h3 class="ke_footer_title">Stay Connected</h3>
						<p class="ke_footer_msg">
							Join our mailing list and stay connected with the latest news and services about TiFi.
						</p>
						<div class="email_address">
							<el-autocomplete
								size="small"
								style="width: 100%"
								v-model="homeEmail"
								:fetch-suggestions="querySearchEmail"
								:trigger-on-focus="false"
							></el-autocomplete>
						</div>
						<p
							class="btn ke_common_btn"
							@click="sendData"
							v-loading="loading"
							element-loading-spinner="el-icon-loading"
							element-loading-background="#d35c58"
						>
							Send
						</p>
					</div>
				</div>
			</div>
			<p class="line"></p>
			<p class="foot_less_msg ke_footer_msg main_content">
				Copyright 2021 TiFi | All rights reserved.
				<router-link to="/privacy-policy" style="color: #fff">Privacy Policy</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			homeEmail: '',
			loading: false
		}
	},
	methods: {
		// 邮箱填写过滤
		createFilter(queryString) {
			return (item) => {
				return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
			}
		},
		// 邮箱自动填充后缀名
		querySearchEmail(queryString, callback) {
			const emailList = [
				{ value: '@gmail.com' },
				{ value: '@yahoo.com' },
				{ value: '@ymail.com' },
				{ value: '@live.com' },
				{ value: '@hotmail.com' },
				{ value: '@aol.com' },
				{ value: '@webmail.co.za' },
				{ value: '@vodamail.co.za' },
				{ value: '@iafrica.com' }
			]
			let results = []
			let queryList = []
			emailList.map((item) => {
				queryList.push({ value: queryString.split('@')[0] + item.value })
			})
			results = queryString ? queryList.filter(this.createFilter(queryString)) : queryList
			callback(results)
		},
		sendData() {
			if (!this.homeEmail) return this.$message.error('Email can not be empty')
			this.loading = true
			if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(this.homeEmail)) {
				console.log(this.homeEmail)
				this.loading = false
			}
			console.log(this.$axios)
		}
	}
}
</script>

<style scoped lang="scss">
.footer_top {
	background-color: var(--ke-primary-red);
	height: 600px;
	position: relative;
	.main_content {
		.left_img {
			width: 406px;
			position: absolute;
			bottom: -1px;
			left: 15%;
		}
		.content {
			position: absolute;
			left: 50%;
			top: 80px;
			h1 {
				padding-bottom: 80px;
			}
			p {
				width: 70%;
				padding-bottom: 50px;
			}
			img {
				width: 311px;
				height: 98px;
			}
		}
	}
}
.footer_bottom {
	margin-top: 0;
	padding: 50px 0;
	background-color: var(--ke-primary-deep-brown);
	.main_content {
		.content {
			display: flex;
			justify-content: space-between;
			padding: 50px 0;
			.common_list {
				color: #fff;
				margin-top: 70px;
			}
			.footer_01 {
				width: 30%;
				img {
					width: 133px;
					height: 61.15px;
				}
				p {
					margin-top: 80px;
					color: #fff;
					span {
						font-weight: bold;
					}
				}
			}
			.footer_02,
			.footer_03 {
				ul {
					@extend .common_list;
					li {
						margin: 23px 0;
						a {
							color: inherit;
						}
					}
				}
			}
			.footer_04 {
				width: 30%;
				p {
					@extend .common_list;
				}
				.email_address {
					margin-top: 20px;
					::v-deep .el-input {
						border-bottom: 1px solid #75757f;
						.el-input__inner {
							color: #fff;
							box-sizing: content-box;
							padding: 4px 0px;
							font-size: 20px;
							height: 50px;
							padding: 0;
							border: none;
							background-color: transparent;
							&:focus {
								border-color: transparent;
							}
						}
					}
				}
				.btn {
					overflow: hidden;
					width: 52%;
					padding: 15px 0;
				}
			}
		}
	}
	.line {
		width: 90%;
		background-color: var(--ke-primary-red);
		padding: 0.5px 0;
		margin: 0 auto;
	}
	.foot_less_msg {
		padding: 40px 0;
		color: #fff;
	}
}

@media screen and (max-width: 1246px) {
	.Home {
		.footer_top {
			background-color: var(--ke-primary-red);
		}
	}
	.footer_top {
		background-color: #fa5a5a;
		position: initial;
		height: auto !important;
		.main_content {
			display: flex;
			flex-direction: column-reverse;
			.left_img {
				margin-bottom: -1px;
				width: 100%;
				position: initial;
			}
			.content {
				position: initial;
				text-align: center;
				padding: 200px 0;
				h1 {
					padding: 0;
				}
				.line {
					margin: 30px auto;
					margin-bottom: 80px;
					width: 40%;
					padding: 4px 0;
					background-color: var(--ke-primary-deep-brown);
				}
				p {
					margin: 0 auto;
					line-height: 50px;
					width: 100%;
				}
				img {
					margin: 0 auto;
				}
			}
		}
	}
	.footer_bottom {
		.main_content {
			.content {
				flex-wrap: wrap;
				.footer_01,
				.footer_04 {
					width: 100%;
				}
				.footer_02,
				.footer_03 {
					margin-top: 100px;
					ul {
						margin-top: 50px;
					}
				}
				.footer_01 {
					text-align: left;
					p {
						line-height: 50px;
						margin-top: 35px;
					}
				}
				.footer_02 {
					width: 70%;
				}
				.footer_03 {
					width: 30%;
				}
				.footer_04 {
					margin-top: 100px;
					p {
						margin: 50px 0;
					}
					.btn {
						font-weight: 550;
						font-size: 35px;
						padding: 40px 0;
						width: 100%;
					}
				}
			}
		}
		.foot_less_msg {
			text-align: center;
			font-size: 27px;
		}
	}
}
</style>
