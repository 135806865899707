<template>
	<div class="main_container">
		<ke-header></ke-header>
		<router-view v-slot="{ Component }">
			<transition name="tran">
				<component :is="Component" />
			</transition>
		</router-view>
		<ke-footer></ke-footer>
	</div>
</template>

<script>
import KeHeader from './Components/header.vue'
import KeFooter from './Components/footer.vue'
export default {
	components: {
		KeHeader,
		KeFooter
	}
}
</script>

<style lang="scss">
.main_container {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.tran-enter-from,
.tran-leave-to {
	opacity: 0;
}
.tran-enter-to,
.tran-leave-from {
	opacity: 0;
}
.tran-enter-active,
.tran-leave-active {
	transition: opacity 2s;
}

h1,
h2,
h3,
p,
li,
div,
span {
	font-family: Montserrat;
}
</style>
