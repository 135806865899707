<template>
	<div class="header_container" :class="menuOpen ? 'menu_open' : 'menu_close'">
		<div class="header_content">
			<img class="logo" src="~@/assets/ke-img/logo.svg" alt="logo" @click="$router.push('/home')" />
			<img @click="menuOpen = !menuOpen" class="menu-logo" :src="menuOpen ? openIcon : closeIcon" alt="menu-logo`" />
			<ul>
				<li
					:class="item.id === activeLi ? `active li-${item.id}` : `li-${item.id}`"
					v-for="item in headerLink"
					:key="item.id"
					@click="changeActiveLi(item.id, item.link)"
				>
					{{ item.text }}
					<ul v-if="item.id === 4" class="height_hidden">
						<li>+254719334232</li>
						<li>+254724924490</li>
					</ul>
				</li>
			</ul>
		</div>
		<div class="menu_list">
			<ul>
				<li
					:class="item.id === activeLi ? `active li-${item.id}` : `li-${item.id}`"
					v-show="item.id !== 4"
					v-for="item in headerLink"
					:key="item.id"
					@click="changeActiveLi(item.id, item.link)"
				>
					{{ item.text }}
				</li>
				<li class="li-8">
					<p>Contact</p>
					<p>+254719334232</p>
					<p>+254724924490</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			activeLi: null,
			headerLink: [
				{ id: 0, link: '/home', text: 'Home' },
				{ id: 1, link: '/about-us', text: 'About us' },
				{ id: 2, link: '/employer', text: 'Employers' },
				{ id: 3, link: '/employee', text: 'Employees' },
				{ id: 4, text: 'Contact' },
				{ id: 5, link: '/faq', text: 'FAQ' },
				{ id: 6, text: 'Request Demo' }
			],
			menuOpen: false,
			openIcon: require('../../../assets/ke-img/menu-logo-close.svg'),
			closeIcon: require('../../../assets/ke-img/menu-logo.svg')
		}
	},
	computed: {
		routePath() {
			return this.$route.path
		}
	},
	watch: {
		routePath: {
			handler: 'switchActive',
			immediate: true
		}
	},
	methods: {
		switchActive(val) {
			this.headerLink.forEach((_) => {
				if (_.link && _.link.includes(val)) {
					this.activeLi = _.id
				} else {
					this.activeLi = null
				}
			})
		},
		changeActiveLi(id, link) {
			this.menuOpen = false
			this.activeLi = id
			link && this.$router.push(link)
		}
	}
}
</script>

<style scoped lang="scss">
.header_container {
	z-index: 999;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 80%;
	.header_content {
		padding: 20px 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #28283b;
		border-bottom: 10px solid var(--ke-primary-red);
		.logo {
			cursor: pointer;
			width: 100px;
			height: 50px;
		}
		ul {
			display: table;
			li {
				display: table-cell;
				vertical-align: middle;
				font-family: Montserrat;
				font-size: 20px;
				color: #fff;
				padding: 0 20px;
				// width: 128px;
				text-align: center;
				cursor: pointer;
			}
			.li-4 {
				position: relative;
				&:hover {
					.height_hidden {
						transform: rotateX(0deg) !important;
					}
				}
				.height_hidden {
					position: absolute;
					top: 50px;
					left: -15px;
					// transform: translateX(-50%);
					background-color: #fff;
					transition: all 0.3s ease;
					transform: rotateX(-90deg); //沿着X轴3d旋转，看不见
					transform-origin: center top; //变换原点
					//transition: transform 0.3s ease;
					will-change: max-height;
					display: flex;
					flex-direction: column;
					border-radius: 10px;
					li {
						padding: 10px;
						color: var(--ke-primary-red);
					}
				}
			}
			.li-7 {
				font-size: 14px;
				border-radius: 10px;
				padding: 13px 30px;
				background-color: var(--ke-primary-red);
			}
			.active {
				font-weight: bold;
				color: var(--ke-primary-red);
			}
			.active.li-7 {
				color: #fff;
				font-weight: normal;
			}
		}
		.menu-logo {
			display: none;
		}
	}
	.menu_list {
		display: none;
	}
}

@media screen and (max-width: 1246px) {
	.header_container {
		width: 100%;
		.header_content {
			position: relative;
			.hover-height-show {
				transform: rotateY(0deg) !important;
			}
			.height-hidden {
				transform: rotateY(-90deg); //沿着X轴3d旋转，看不见
			}
			ul {
				display: none;
			}
			.menu-logo {
				cursor: pointer;
				display: block;
				width: 50px;
				height: 30px;
			}
		}
	}
	.menu_open {
		bottom: 0;
		right: 0;
		.header_content {
			border: 0;
		}
		.menu_list {
			display: block;
			width: 100%;
			height: 100%;
			background-color: #28283b;
			ul {
				padding: 100px 150px;
				.active {
					font-weight: bold;
					color: var(--ke-primary-red);
				}
				.active.li-7 {
					color: #fff;
					font-weight: normal;
				}
				li {
					cursor: pointer;
					font-size: 35px;
					color: #fff;
					text-align: center;
					margin-bottom: 80px;
				}
				.li-7 {
					margin-top: 120px;
					font-size: 35px !important;
					border-radius: 6px;
					padding: 23px 30px;
					background-color: var(--ke-primary-red);
				}
				.li-8 {
					p {
						margin: 20px 0;
						font-size: 35px;
					}
				}
			}
		}
	}
	.menu_close {
		.menu_list {
			display: none;
		}
	}
}
</style>
